@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

:root {
  --primary: #12bca2;
  --secondary: #1548df;
  --writing: #333;
}

h1,
h2,
h3 {
  margin: 0;
}

body {
  margin: 0;
  color: var(--writing);
  font-family: 'Poppins';
  background: #f2f2f2;
}

nav {
  background: var(--primary);
  padding: 10px 20px;
  text-align: center;
  overflow: hidden;
}

nav a {
  color: #f2f2f2;
  margin: 10px;
  display: inline-block;
}

nav h1 {
  color: #fff;
}

.page {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  padding-top: 0px;
}

i {
  color: #bbb;
  margin-left: 10px;
  font-size: 1.2em;
  padding: 6px;
  background: #eee;
  border-radius: 50%;
  cursor: pointer;
}


/* ----------------------chart cards---------------------- */
.chart-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
}
.title {
  padding: 10px;
}
.chart-card {
  text-align: center;
  width: 100%;
  padding: 10px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
}


.chart-card .buttons {
  text-align: right;
}



/* ----------------------forms---------------------- */
.form-grid {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fc;
  gap: 40px;
  margin-right: 20px;
  margin-left: 20px;
  

}
.edit-grid {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  margin-right: 20px;
  margin-left: 20px;
  

}
.chart-input {
  background: #fff;
  padding: 20px;
  max-width: 450px;
  height: fit-content;
  width: 90%;
  margin: 0 auto;
  border-radius: 6px;
}
form {
  background: #fff;
  
  max-width: 450px;
  margin: 0 auto;
  border-radius: 6px;
}

form input,
form textarea {
  display: block;
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin: 10px 0 20px 0;
}

form button {
  background: var(--primary);
  color: #fff;
  border: 0;
  border-radius: 6px;
  padding: 6px 8px;
  font-family: "Poppins";
  cursor: pointer;
}

.submit {
  margin-top: 20px;
  width: 100%;
}

.irregular-block {
  display: flex;
  align-items: center;
}
.irregular-icons{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
/* ----------------------order-by buttons---------------------- */
.order-by button {
  margin-right: 10px;
  background: var(--primary);
  color: #fff;
  border: 0;
  padding: 4px 8px;
  border-radius: 6px;
  font-family: "Poppins";
  cursor: pointer;
}

/* ----------------------table---------------------- */
table {
  margin: auto;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

td, th {
  white-space: nowrap;
  border: 1px solid #ddd;
  padding: 20px;
}
/* ----------------------Mobile View---------------------- */
@media only screen and (max-width: 650px) {
  .chart-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 450px) {
  .chart-grid {
    grid-template-columns: 1fr;
    
  }
  .form-grid {
    grid-template-columns: 1fr;
  }
}
  
/* ----------------------Study Page---------------------- */
.study-mode {
  background: var(--primary);
  width: 100%;
  height: 200px;
  border-radius: 20px;
  color: #fff;
  border: 0;
  border-radius: 6px;
  padding: 6px 8px;
  font-family: "Poppins";
  cursor: pointer;
}
/* ----------------------Congrats---------------------- */
.congrats {
  text-align: center;
}

/* ----------------------Button---------------------- */
.button {
  background: var(--primary);
  margin-right: 10px;
  color: #fff;
  border: 0;
  border-radius: 6px;
  padding: 6px 8px;
  font-family: "Poppins";
  cursor: pointer;
}
/* ----------------------Sign in---------------------- */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.spacer {
  margin-bottom: 40px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* ----------------------Components---------------------- */
.header-bar {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  background-color: #12bca2;
  color: white;
  border-radius: 20px;
}
/* ----------------------About---------------------- */
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.about-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 40px;
}

.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 80px;
}

.about-subtitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.about-text {
  font-size: 18px;
  text-align: center;
  width: 80%;
  margin-bottom: 40px;
}

.about-break {
  height: 2px;
  width: 80px;
  border-radius: 50px;
  background-color: #333;
  margin-bottom: 40px;
}

/* ----------------------Spanish Letter Bar---------------------- */
.spanish-letter-bar {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  align-content: center;

}
.letter-button {
  width: 14%;
  margin-left: 5px;
  margin-right: 5px;
}

/* ----------------------sets---------------------- */
.set-bar {
  background: var(--primary);
  width: 100%;
  height: 60px;
  border-radius: 20px;
  color: #fff;
  border: 0;
  border-radius: 6px;
  padding: 6px 8px;
  font-family: "Poppins";
  cursor: pointer;
  margin-bottom: 20px;
  vertical-align: middle;
}
